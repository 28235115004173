import App from '../App';
import { Route } from "react-router-dom";
import XPComponent from './XPComponent';
import Win98theme from './Win98theme';
import DemoPage1 from './DemoPage1';
import Changelog from './Changelog';
import Blog from './Blog';
import Found from './Found';
import Experimental from './Experimental';

export const routes = (finalIsDarkMode) => (
  <>
    <Route path="/" element={<App {...{ finalIsDarkMode }} />} />
    <Route path="changelog" element={<Changelog {...{ finalIsDarkMode }} />} />
    <Route path="blog" element={<Blog {...{ finalIsDarkMode }} />} />
    <Route path="found" element={<Found {...{ finalIsDarkMode }} />} />
    <Route path="expieramental" element={<Experimental {...{ finalIsDarkMode }} />} />

    <Route path="xpComponent" element={<XPComponent />} />
    <Route path="Win98theme" element={<Win98theme />} />

    <Route path="demoPage1" element={<DemoPage1 />} />
  </>
);