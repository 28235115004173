import { Link } from "react-router-dom";
import { space6, BWHeader } from "../dist/helpers"
import React from "react";

import ArrowController from "./ArrowController";

let takePart = (image, part1, part2, multiplier=1) => {
  let width = multiplier * 256 - (multiplier *  64 * 3)
  let margin = multiplier *  64 * part1
  let margin2 = multiplier *  64 * part2
  return (
    <>
      <div
        style={{
          width: `${ width }px`,
          height: `${ width }px`,
          overflow: "hidden",
          display: "inline-block",
        }}
      >
        <img
          alt="spriteFrame1"
          src={image}
          style={{
            margin: `-${ margin }px 0px 0px -${ margin2 }px`,
          }}
        />
      </div>
    </>
  );
}

export const SpriteFourStep = (frame, image, offset = 0, offset2 = 0, multiplier=1) => {
  if (frame === 0) {
    return takePart(image, 0 + offset2, 0 + offset, multiplier)
  } else if (frame === 1) {
    return takePart(image, 0 + offset2, 1 + offset, multiplier)
  } else if (frame === 2) {
    return takePart(image, 0 + offset2, 2 + offset, multiplier)
  } else if (frame === 3) {
    return takePart(image, 0 + offset2, 3 + offset, multiplier)
  }
}

export const SpriteTwoStep = (frame, image, offset = 0, offset2 = 0, multiplier=1) => {
  if (frame > 0) {
    return takePart(image, 0 + offset2, 0 + offset, multiplier)
  } else {
    return takePart(image, 0 + offset2, 1 + offset, multiplier)
  }
}

export default function Experimental(props) {
  const { finalIsDarkMode } = props;

  return (
    <div>
      <center>
        { BWHeader(finalIsDarkMode, "Experimental", "This is the work in progress kinda stuff") }
        { space6 }

        <ArrowController />


        { space6 }
        <Link to="/xpComponent" style={{ paddingRight: "1rem" }}>
          <button className="btn btn-light" type="button" style={{ marginTop: "1rem" }}>
            Checkout XPComponent!
          </button>
        </Link>

        { space6 }
        <Link to="/Win98theme" style={{ paddingRight: "1rem" }}>
          <button className="btn btn-light" type="button" style={{ marginTop: "1rem" }}>
            Checkout Win98theme!
          </button>
        </Link>
        { space6 }

      </center>
    </div>
  )
};
