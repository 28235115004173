import React, { useState } from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { MenuItem } from '@mui/material';
import AndrewDilks from "../images/AndrewDilks.png"
import AndrewDilksDark from "../images/AndrewDilksDark.png"
import { space3 } from "./helpers"
import useWindowDimensions from "./UseWindowDimensions";

const mainMenuItems = (finalIsDarkMode, setExpandIsEnabled) => (
  <>
    <MenuItem
      component={Link} to={'/changelog'} onClick={() => setExpandIsEnabled()}
      style={{ color: finalIsDarkMode ? 'white': '', "display": "flex", "justifyContent": "center" }}
    >
      Changelog
    </MenuItem>
    <MenuItem
      component={Link} to={'/blog'} onClick={() => setExpandIsEnabled()}
      style={{ color: finalIsDarkMode ? 'white': '', "display": "flex", "justifyContent": "center" }}
    >
      Blog
    </MenuItem>
    <MenuItem
      component={Link} to={'/found'} onClick={() => setExpandIsEnabled()}
      style={{ color: finalIsDarkMode ? 'white': '', "display": "flex", "justifyContent": "center" }}
    >
      Found
    </MenuItem>
    <MenuItem
      component={Link} to={'/expieramental'} onClick={() => setExpandIsEnabled()}
      style={{ color: finalIsDarkMode ? 'white': '', "display": "flex", "justifyContent": "center" }}
    >
      Experimental
    </MenuItem>
  </>
);

const insideNavbar = (darkModeToggle, finalIsDarkMode, setExpandIsEnabled, snowToggle) => (
  <Container style={{ "display": "flex", "justifyContent": "right" }}>
    <div style={{ width: "70%", padding: "10px" }}>
      <Navbar.Brand href="/">
        <img
          style={{ maxWidth: "100%", maxHeight: "100px" }}
          src={ finalIsDarkMode ? AndrewDilksDark : AndrewDilks }
          alt="Andrew Dilks Logo"
        />
      </Navbar.Brand>
    </div>

    <div style={{ paddingRight: "20px" }}>
      <Navbar.Toggle
        style={{ float: "right" }}
        aria-controls="navbarScroll"
        onClick={() => setExpandIsEnabled()}
      />
    </div>

    <Navbar.Collapse id="navbarScroll">
      <Nav className="me-auto" navbarScroll >
        { mainMenuItems(finalIsDarkMode, setExpandIsEnabled) }
        { darkModeToggle() }
        { snowToggle() }
      </Nav>
    </Navbar.Collapse>
  </Container>
);

const outsideNav = (darkModeToggle, finalIsDarkMode, expandIsEnabled, setExpandIsEnabled, shouldRemoveTransparent, snowToggle) => (
  <div>
    <Navbar
      expanded={expandIsEnabled}
      variant={ finalIsDarkMode ? "dark" : "light"}
      expand="lg"
      style={ finalIsDarkMode ? {
        backgroundColor: (!shouldRemoveTransparent) ? "rgba(33, 37, 41, .7)" : "rgba(33, 37, 41, 1)",
        maxWidth: "93%",
        borderColor: "white",
        borderWidth: "thick",
        border: "5px solid white",
        borderRadius: "25px",
      } : {
        backgroundColor: (!shouldRemoveTransparent) ? "rgba(240, 248, 255, .7)" : "AliceBlue",
        boxShadow: "0px 5px 40px DarkSlateGray",
        maxWidth: "93%",
        borderColor: "black",
        borderWidth: "thick",
        border: "5px solid",
        borderRadius: "25px",
      }}
    >
      { insideNavbar(darkModeToggle, finalIsDarkMode, setExpandIsEnabled, snowToggle) }
    </Navbar>
  </div>
);

export default function ADNavbar(props) {

  const { isDarkModeOverride: finalIsDarkMode, darkModeToggle, shouldRemoveTransparent, snowToggle } = props;
  const [expandIsEnabled, setExpandIsEnabled] = useState(false);
  const { width } = useWindowDimensions();

  const toggleExpanded = () => {
    setExpandIsEnabled(!expandIsEnabled)
  }

  // const small = width < 580; // 580 comes from safari tool
  const small = width < 1080; // 580 comes from safari tool
  const shouldCollapse = expandIsEnabled && small;

  return (
    <div
      style={{
        // keeps navbar on top of scrolling
        position: "sticky",
        top: 0,
        // keeps navbar above everything else
        "zIndex": 1
      }}
    >
      {/* <center> {width} </center> */}
      <br/>
      <center>
        { outsideNav(darkModeToggle, finalIsDarkMode, shouldCollapse, toggleExpanded, shouldRemoveTransparent, snowToggle) }
      </center>
      { space3 }
    </div>
  )
};
